import React from 'react';

import Canvas from 'app/components/canvas';

const App = () => {
  return (
    <div className="App">
      <div className="header-info">
        <h1 className="app-title">
          <a 
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/sanvir-dessai"
          >
            Sanvir Dessai
          </a>
        </h1>
        <p className="project-source">
          My personal blog is still a work in progress. For now, I hope you enjoy these irregular Sine waves.
        </p>
      </div>
      <Canvas />
    </div>
  );
}

export default App;
